.folder {
    width: 84px;

    height: 62px;
    color: white;
    background: white;
    position: relative;
}

.folder::before {
    content: '';
    position: absolute;
    top: -3.6px;
    width: 40px;
    height: 5px;
    background: white;
    border-radius: 5px 0 0 0;
    clip-path: path('M 0 0 L 32 0 C 37 2, 35 16, 40 18 L 0 50 z');
}

.folder::after {
    content: '';
    position: absolute;
    /* left: 40px; */
    width: 84px;
    height: 5px;
    top: 0px;
    background-color: var(--color);
    /* border-radius: 0 0 5px 5px; */

}