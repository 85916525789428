.wrapper {
    width: 100%;
    height: 100%;
    background: transparent;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container {
    position: relative;
    display: grid;
    grid-template-columns: repeat(4, minmax(100px, 1fr));
    grid-gap: 25px;
    padding: 25px;
    background: white;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.05);
    will-change: width, height;
}

.item {
    width: 100%;
    height: 100%;
    background: white;
    border-radius: 5px;
    will-change: transform, opacity;
}